import type {
  EditorialObject,
  Item,
  Language,
  Service,
} from '@yleisradio/areena-types';
import { Query } from 'types/query';
import { isTestEnvironment } from './environment';
import { getItemService } from './item';
import {
  favoritesRoutes,
  guideRoutes,
  historyRoutes,
  liveRoutes,
  packageRoutes,
  personalRoutes,
  serviceRoutes,
} from './routes';
import { ParsedUrlQuery, stringify } from 'querystring';

export function getCanonicalBaseURL(language: Language): string {
  if (isTestEnvironment()) {
    return language === 'sv'
      ? 'https://arenan-test.yle.fi'
      : 'https://areena-test.yle.fi';
  } else {
    return language === 'sv'
      ? 'https://arenan.yle.fi'
      : 'https://areena.yle.fi';
  }
}

export function getCanonicalProgramPath(
  item: Item,
  language: Language
): string {
  const service = getItemService(item);
  const servicePrefix =
    service === 'radio' ? `/${serviceRoutes[service][language]}` : '';

  return `${servicePrefix}/${item.id}`;
}

export function getCanonicalProgramURL(
  item: EditorialObject,
  language: Language
): string {
  return `${getCanonicalBaseURL(language)}${getCanonicalProgramPath(
    item,
    language
  )}`;
}

function getCanonicalProgramPagePath(
  path: string,
  language: Language
): string | null {
  const [, serviceSegment, programId] =
    /^(?:\/(podcastit|poddar))?\/(1-\d+)\/?(?:[?#]|$)/.exec(path) || [];

  if (programId) {
    const isRadioItem = !!serviceSegment;
    const item: Item = {
      id: programId,
      type: isRadioItem ? 'RadioContent' : 'TVContent',
    };

    return getCanonicalProgramPath(item, language);
  }

  return null;
}

function getCanonicalPersonalPagePath(
  path: string,
  language: Language
): string | null {
  const [match, serviceMatch] =
    path.match(/^(?:\/(tv|podcastit|poddar))?\/(?:omat|mina)\/?(?:[?#]|$)/) ||
    [];
  const personalSegment = personalRoutes[language];

  if (serviceMatch) {
    const service: Service = serviceMatch === 'tv' ? 'tv' : 'radio';
    const serviceSegment = serviceRoutes[service][language];

    return `/${serviceSegment}/${personalSegment}`;
  }

  if (match) {
    return `/${personalSegment}`;
  }

  return null;
}

function getCanonicalPersonalSubpagePath(
  path: string,
  language: Language
): string | null {
  const [, serviceMatch, subpageMatch] =
    path.match(
      /^\/(tv|podcastit|poddar)\/(?:omat|mina)\/(favoriter|historia|suosikit)\/?(?:[?#]|$)/
    ) || [];

  if (serviceMatch && subpageMatch) {
    const service: Service = serviceMatch === 'tv' ? 'tv' : 'radio';
    const serviceSegment = serviceRoutes[service][language];
    const subpageSubpath =
      subpageMatch === 'historia'
        ? historyRoutes[language]
        : favoritesRoutes[language];

    return `/${serviceSegment}/${subpageSubpath}`;
  }

  return null;
}

function getCanonicalFrontPagePath(
  path: string,
  language: Language
): string | null {
  const [, serviceMatch] = /^\/(tv|podcastit|poddar)\/?$/.exec(path) || [];

  if (serviceMatch) {
    const service: Service = serviceMatch === 'tv' ? 'tv' : 'radio';
    return `/${serviceRoutes[service][language]}`;
  }

  return null;
}

function getCanonicalGuidePathFromPath(
  path: string,
  language: Language,
  query: Query
): string | null {
  const [match, serviceMatch] =
    /^\/(tv|podcastit|poddar)\/(?:opas|guide)\/?(?:[?#]|$)/.exec(path) || [];

  if (match) {
    const service: Service = serviceMatch === 'tv' ? 'tv' : 'radio';
    const serviceSegment = serviceRoutes[service][language];
    const guideSegment = guideRoutes[language];

    const dateParameter = getQueryParameter('t', query);
    const canonicalQuery = dateParameter
      ? `?${new URLSearchParams({ t: dateParameter }).toString()}`
      : '';

    return `/${serviceSegment}/${guideSegment}${canonicalQuery}`;
  }

  return null;
}

function getCanonicalLivePathFromPath(
  path: string,
  language: Language
): string | null {
  const matches = /^\/(?:suorat|direkt)\/?$/.test(path);

  if (matches) {
    return `/${liveRoutes[language]}`;
  }

  return null;
}

export function getCanonicalPackagePagePath(
  path: string,
  language: Language,
  _query: Query,
  slugs?: Record<string, string> | null
) {
  const service = serviceFromClientPath(path);

  const [, id] =
    /^\/(?:tv|podcastit|poddar)\/(?:ohjelmat|program)\/(57-[\w-]+|30-\d+|)\/?(?:[?#]|$)/.exec(
      path
    ) || [];

  if (id) {
    return getCanonicalPackagePath(
      { id, type: service === 'tv' ? 'TVContent' : 'RadioContent' },
      language
    );
  }

  const [, slug] =
    /^\/(?:tv|podcastit|poddar)\/(?:ohjelmat|program)\/([\w-]+)$/.exec(path) ||
    [];

  if (slug && slugs) {
    const viewId = getViewIdBySlug(slug, slugs);
    if (viewId) {
      return getCanonicalPackagePath(
        {
          id: viewId,
          type: service === 'tv' ? 'TVContent' : 'RadioContent',
        },
        language
      );
    }
  }
  return null;
}

export function getCanonicalPath(
  path: string,
  language: Language,
  query: Query,
  slugs?: Record<string, string> | null
): string | null {
  return [
    getCanonicalFrontPagePath,
    getCanonicalGuidePathFromPath,
    getCanonicalLivePathFromPath,
    getCanonicalProgramPagePath,
    getCanonicalPersonalPagePath,
    getCanonicalPersonalSubpagePath,
    getCanonicalPackagePagePath,
  ].reduce<string | null>(
    (prev, fn) => prev || fn(path, language, query, slugs),
    null
  );
}

export function getCanonicalURL(
  path: string,
  language: Language,
  query: Query,
  slugs?: Record<string, string> | null
): string | null {
  const canonicalPath = getCanonicalPath(path, language, query, slugs);

  return canonicalPath
    ? `${getCanonicalBaseURL(language)}${canonicalPath}`
    : null;
}

function getQueryParameter(parameter: string, query: Query): string | null {
  const value = query[parameter];
  return (Array.isArray(value) ? value[0] : value) || null;
}

export function getCanonicalPackagePath(item: Item, language: Language) {
  const service = getItemService(item);

  return `/${serviceRoutes[service][language]}/${packageRoutes[language]}/${item.id}`;
}

export function getCanonicalGuidePath(
  service: Service,
  language: Language
): string {
  return `/${serviceRoutes[service][language]}/${guideRoutes[language]}`;
}
const VISIBLE_QUERY_PARAMS = ['t', '_c', 'autoplay', 'q', 'service'];

const filterQueryParameters = (query: ParsedUrlQuery): ParsedUrlQuery =>
  Object.fromEntries(
    Object.entries(query).filter(([key]) => VISIBLE_QUERY_PARAMS.includes(key))
  );

export const prettyPath = (asPath: string, query: ParsedUrlQuery): string => {
  const filteredParams = {
    ...filterQueryParameters(query),
  };
  const params = new URLSearchParams(stringify(filteredParams));
  const urlParams = params.size > 0 ? `?${params.toString()}` : '';

  const path = asPath.replace(/\?.*$/, '');
  return `${path}${urlParams}`;
};

export const getViewIdBySlug = (slug: string, slugs: Record<string, string>) =>
  Object.keys(slugs).find((key) => slugs[key] === slug);

export const serviceFromClientPath = (path: string): Service =>
  path.startsWith('/podcastit') || path.startsWith('/poddar') ? 'radio' : 'tv';

export const isPackageViewPathWithSlug = (path: string): boolean => {
  return /^\/(?:tv|podcastit|poddar)\/(?:ohjelmat|program)\/[^35]/.test(path);
};

export function getRssFeedUrl(item: Item, language: Language): string | null {
  if (item.type !== 'RadioSeries') return null;

  if (isTestEnvironment())
    return `https://feeds.test.yle.fi/areena/v1/series/${item.id}.rss?language=${language}`;

  return `https://feeds.yle.fi/areena/v1/series/${item.id}.rss?language=${language}`;
}

export function getOtherLanguageURL(
  path: string,
  query: Query,
  language: Language,
  service: Service | undefined
): string {
  const targetLanguage: Language = language === 'fi' ? 'sv' : 'fi';
  const targetBaseURL = getCanonicalBaseURL(targetLanguage);
  const targetPath = getCanonicalPath(path, targetLanguage, query);
  const frontPagePath = `/${serviceRoutes[service || 'tv'][targetLanguage]}`;

  return `${targetBaseURL}${targetPath || frontPagePath}`;
}

export function getItemIdFromClientPath(path: string): string | null {
  const [, id] = /\/(1-\d+)/.exec(path) || [];
  return id || null;
}
