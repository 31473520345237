import { Control } from '@yleisradio/areena-types';
import React from 'react';
import PlayIcon from 'assets/playIcon.svg';
import { Button, ButtonPadding, ButtonSize, ButtonVariant } from './Button';

interface PlayButtonProps {
  control: Control | undefined;
  onClick: React.MouseEventHandler | undefined;
  padding: ButtonPadding | undefined;
  size: ButtonSize | undefined;
  variant: ButtonVariant | undefined;
  width: 'full' | undefined;
}

export const PlayButton: React.FunctionComponent<PlayButtonProps> = ({
  control,
  onClick,
  padding,
  size,
  variant = 'primary',
  width,
}) => {
  if (!control) return null;

  return (
    <Button
      text={control.title || ''}
      onClick={onClick}
      padding={padding}
      size={size}
      variant={variant}
      iconLeft={PlayIcon}
      width={width}
    />
  );
};
