import { Control } from '@yleisradio/areena-types';
import React from 'react';
import Icon from 'assets/chevronLeft.svg';
import { useTranslation } from 'hooks/useTranslation';
import { Button } from '..';
import styles from './SeriesLink.module.scss';

interface SeriesLinkProps {
  control: Control;
  onClick?: React.MouseEventHandler | undefined;
}

export const SeriesLink: React.FunctionComponent<SeriesLinkProps> = ({
  control,
  onClick,
}) => {
  const t = useTranslation();
  const ariaLabel = `${t('backToSeries')} ${control.title || ''}`;

  return (
    <div className={styles.seriesLinkWrapper}>
      <Button
        pointer={control.destination}
        text={control.title || ''}
        disabled={!!control.disabled}
        iconLeft={Icon}
        onClick={onClick}
        variant="text"
        padding="vertical"
        size="sm"
        aria-label={ariaLabel}
      />
    </div>
  );
};
