import { getTranslation } from 'services/translations';
import { useUILanguage } from './useUILanguage';

export type Key = Parameters<typeof getTranslation>[0];

export function useTranslation(): (key: Key) => string {
  const language = useUILanguage();
  return function t(key) {
    return getTranslation(key, language);
  };
}
