export const serviceRoutes = {
  tv: {
    fi: 'tv',
    sv: 'tv',
  },
  radio: {
    fi: 'podcastit',
    sv: 'poddar',
  },
  broadcasts: {
    fi: 'suorat',
    sv: 'direkt',
  },
  search: {
    fi: 'hae',
    sv: 'sok',
  },
  children: {
    fi: 'lapset',
    sv: 'barn',
  },
};

export const guideRoutes = {
  fi: 'opas',
  sv: 'guide',
};

export const liveRoutes = {
  fi: 'suorat',
  sv: 'direkt',
};

export const packageRoutes = {
  fi: 'ohjelmat',
  sv: 'program',
};

export const personalRoutes = {
  fi: 'omat',
  sv: 'mina',
};

export const personalTVRoutes = {
  fi: 'omat?t=tv-ohjelmat',
  sv: 'mina?t=tv-program',
};

export const personalPodcastRoutes = {
  fi: 'omat?t=podcastit',
  sv: 'mina?t=poddar',
};

export const historyRoutes = {
  fi: `${personalRoutes['fi']}/historia`,
  sv: `${personalRoutes['sv']}/historia`,
};
export const favoritesRoutes = {
  fi: `${personalRoutes['fi']}/suosikit`,
  sv: `${personalRoutes['sv']}/favoriter`,
};

export const searchRoutes = {
  fi: 'hae',
  sv: 'sok',
};
