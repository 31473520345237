import React, { useContext, useMemo } from 'react';

type Value = {
  playQueue2024: boolean;
};

const FeatureFlagContext = React.createContext<Value>({
  playQueue2024: false,
});

export const FeatureFlagProvider: React.FC<
  React.PropsWithChildren<{ playQueue2024: boolean }>
> = ({ children, playQueue2024 }) => {
  const value = useMemo<Value>(() => ({ playQueue2024 }), [playQueue2024]);

  return (
    <FeatureFlagContext.Provider value={value}>
      {children}
    </FeatureFlagContext.Provider>
  );
};

export function useFeatureFlag(): Value {
  return useContext(FeatureFlagContext);
}
