import React from 'react';
import classNames from 'classnames';
import styles from './AreenaFooter.module.scss';
import YleLogo from 'assets/yle.svg';
import YleArenanLogo from 'assets/arenan.svg';
import LastenAreenaLogo from 'assets/lastenAreena.svg';
import { serviceRoutes } from 'utils/routes';
import { useAreenaService } from 'contexts/AreenaServiceContext';
import { useTranslation } from 'hooks/useTranslation';
import { Container } from 'components/Container';
import { getCanonicalBaseURL, getOtherLanguageURL } from 'utils/url';
import { Control } from 'components/Controls/Control';
import { DSLink } from 'components/DSLink';
import { Link } from 'components/Link/Link';
import { useRouter } from 'next/router';

export const FooterFinnish: React.FunctionComponent = () => {
  const { areenaService } = useAreenaService();
  const t = useTranslation();
  const { asPath: path, query } = useRouter();

  const swedishAlternativeUrl = getOtherLanguageURL(
    path,
    query,
    'fi',
    areenaService
  );

  return (
    <div className={styles.footer}>
      <Container>
        <div className={classNames(styles.areenaLinksSection)}>
          <Link
            className={styles.areenaLink}
            lang="sv"
            pointer={{
              uri: swedishAlternativeUrl,
            }}
          >
            <YleArenanLogo aria-hidden className={styles.areenaLogo} />
            <div className={styles.areenaLinkText}>
              {t('otherLanguageLink')}
            </div>
          </Link>

          <Link
            className={styles.areenaLink}
            pointer={{
              uri: `${getCanonicalBaseURL('fi')}/${serviceRoutes['children']['fi']}`,
            }}
          >
            <LastenAreenaLogo aria-hidden className={styles.areenaLogo} />
            <div className={styles.areenaLinkText}>{t('lastenAreena')}</div>
          </Link>
        </div>
        <div className={styles.generalLinksSection}>
          <div className={styles.subLinksSection}>
            <h3 className={styles.subTitle}>{t('footerSupportTitle')}</h3>
            <div className={styles.linksContainer}>
              {' '}
              <DSLink
                pointer={{
                  uri: `https://yle.fi/aihe/s/10005829`,
                  type: 'external-html',
                }}
                linkVariant="internal"
                aria-label={t('tvApps')}
                text={t('tvApps')}
                textSize="s"
              ></DSLink>
              <DSLink
                pointer={{
                  uri: `https://yle.fi/aihe/s/10005823`,
                  type: 'external-html',
                }}
                linkVariant="internal"
                text={t('androidApps')}
                textSize="s"
                aria-label={t('androidApps')}
              ></DSLink>
              <DSLink
                pointer={{
                  uri: `https://yle.fi/aihe/s/10005825`,
                  type: 'external-html',
                }}
                linkVariant="internal"
                aria-label={t('iPhoneAndiPad')}
                text={t('iPhoneAndiPad')}
                textSize="s"
              ></DSLink>
              <DSLink
                pointer={{
                  uri: `https://yle.fi/aihe/areena/ohjeet`,
                  type: 'external-html',
                }}
                linkVariant="internal"
                aria-label={t('allInstructions')}
                text={t('allInstructions')}
                textSize="s"
              ></DSLink>
            </div>
          </div>
          <div className={styles.subLinksSection}>
            <h3 className={styles.subTitle}>{t('footerSomeTitle')}</h3>
            <div className={styles.linksContainer}>
              <DSLink
                pointer={{
                  uri: `https://www.facebook.com/yleareena`,
                  type: 'external-html',
                }}
                linkVariant="internal"
                aria-label={t('facebook')}
                text={t('facebook')}
                textSize="s"
              ></DSLink>
              <DSLink
                pointer={{
                  uri: `https://www.instagram.com/yleareena`,
                  type: 'external-html',
                }}
                linkVariant="internal"
                aria-label={t('instagram')}
                text={t('instagram')}
                textSize="s"
              ></DSLink>
              <DSLink
                pointer={{
                  uri: `https://www.tiktok.com/@yleareena`,
                  type: 'external-html',
                }}
                linkVariant="internal"
                text={t('tikTok')}
                textSize="s"
                aria-label={'tikTok'}
              ></DSLink>
              <DSLink
                pointer={{
                  uri: `https://www.youtube.com/c/yleareena`,
                  type: 'external-html',
                }}
                linkVariant="internal"
                aria-label={t('youtube')}
                text={t('youtube')}
                textSize="s"
              ></DSLink>
            </div>
          </div>
          <div className={classNames(styles.subLinksSection)}>
            <h3 className={styles.subTitle}>{t('footerFeedbackTitle')}</h3>
            <div className={styles.linksContainer}>
              <DSLink
                pointer={{
                  uri: `https://asiakaspalvelu.yle.fi/`,
                  type: 'external-html',
                }}
                linkVariant="internal"
                aria-label={t('customerService')}
                text={t('customerService')}
                textSize="s"
              ></DSLink>
            </div>
          </div>
        </div>
      </Container>
      <div className={styles.yleFiLinkContainer}>
        <Container>
          <Link
            pointer={{ uri: `https://yle.fi`, type: 'external-html' }}
            className={styles.yleFiLink}
          >
            <div className={styles.yleFiLinkContent}>
              <YleLogo className={styles.yleLogo} />
              <span className={styles.yleLinkText}>{t('yleUrl')}</span>
            </div>
          </Link>
        </Container>
      </div>
      <div className={styles.yleRelatedLinkContainer}>
        <Container>
          <div className={styles.yleRelatedLinks}>
            <DSLink
              pointer={{
                uri: `https://yle.fi/aihe/yleisradio`,
                type: 'external-html',
              }}
              aria-label={t('yleCorporation')}
              text={t('yleCorporation')}
              textSize="s"
              theme="light"
              linkVariant="internal"
            ></DSLink>
            <DSLink
              pointer={{
                uri: `https://yle.fi/aihe/yleisradio/ylen-yhteystiedot`,
                type: 'external-html',
              }}
              aria-label={t('yleContactInfo')}
              text={t('yleContactInfo')}
              textSize="s"
              theme="light"
              linkVariant="internal"
            ></DSLink>
            <DSLink
              pointer={{
                uri: `https://yle.fi/aihe/s/yleisradio/evastekaytannot`,
                type: 'external-html',
              }}
              aria-label={t('cookiePolicy')}
              text={t('cookiePolicy')}
              textSize="s"
              theme="light"
              linkVariant="internal"
            ></DSLink>
            <Control
              as={'link'}
              controlKey={'cookieSettings'}
              control={{
                type: 'activator',
                title: t('cookieSettings'),
                tag: 'cookie-consent',
                functionality: {
                  id: 'cookie-consent',
                },
              }}
            ></Control>
            <DSLink
              pointer={{
                uri: `https://yle.fi/aihe/yleisradio/tietosuoja`,
                type: 'external-html',
              }}
              aria-label={t('privacyPolicy')}
              textSize="s"
              theme="light"
              linkVariant="internal"
              text={t('privacyPolicy')}
            ></DSLink>
          </div>
        </Container>
      </div>
    </div>
  );
};
