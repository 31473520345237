import { Pointer, Service } from '@yleisradio/areena-types';
import { useUILanguage } from 'hooks/useUILanguage';
import NextLink from 'next/link';
import React, { ForwardedRef, forwardRef } from 'react';
import { pointerToClientURL } from 'utils/pointer';
import { useAreenaService } from 'contexts/AreenaServiceContext';
import classNames from 'classnames';
import { usePathname } from 'next/navigation';
import { useRouter } from 'next/router';
import { serviceFromClientPath } from 'utils/url';
import { trackLink } from 'services/yleAnalyticsSdk';
import logger from 'services/logger';
import { serviceRoutes } from 'utils/routes';

type PointerType = Pointer['type'];

const AREENA_POINTER_TYPES: readonly PointerType[] = [
  'clip',
  'program',
  'series',
  'app',
  'package',
  'service',
  'view',
  undefined,
];

function isInAppLink(href: string, pointer: Pointer) {
  return (
    AREENA_POINTER_TYPES.includes(pointer.type) &&
    href.startsWith('/') &&
    !href.startsWith('//') &&
    !href.startsWith(`/${serviceRoutes.children.fi}`) &&
    !href.startsWith(`/${serviceRoutes.children.sv}`)
  );
}

type LinkProps = {
  children: React.ReactNode;
  className?: string | undefined;
  download?: boolean | undefined;
  id?: string | undefined;
  lang?: string | undefined;
  onClick?: React.MouseEventHandler<HTMLElement> | undefined;
  classNameCurrentPage?: string | undefined;
  pointer: Pointer;
  service?: Service | undefined;
} & React.AriaAttributes;

export const Link = forwardRef(function Link(
  {
    children,
    download,
    onClick,
    pointer,
    service: serviceOverride,
    classNameCurrentPage,
    ...restProps
  }: LinkProps,
  ref: ForwardedRef<HTMLAnchorElement>
) {
  const language = useUILanguage();
  const { asPath } = useRouter();
  const { areenaService } = useAreenaService();

  const service =
    serviceOverride ?? areenaService ?? serviceFromClientPath(asPath);

  const href = pointerToClientURL(pointer, language, service);
  const pathname = usePathname();

  if (!href) {
    return onClick ? (
      <button onClick={onClick} {...restProps}>
        {children}
      </button>
    ) : (
      <span {...restProps}>{children}</span>
    );
  }

  const onClickWithTracking = (event: React.MouseEvent<HTMLAnchorElement>) => {
    try {
      trackLink(event.currentTarget);
    } catch (e) {
      logger.error(e, 'Tracking link failed');
    }

    if (onClick) {
      onClick(event);
    }
  };

  const className = classNames(
    restProps.className,
    pathname === href && classNameCurrentPage
  );

  if (isInAppLink(href, pointer)) {
    return (
      <NextLink
        download={download}
        href={href}
        onClick={onClickWithTracking}
        {...restProps}
        ref={ref}
        className={className}
      >
        {children}
      </NextLink>
    );
  }

  if (AREENA_POINTER_TYPES.includes(pointer.type)) {
    return (
      <a
        download={download}
        href={href}
        onClick={onClickWithTracking}
        {...restProps}
        ref={ref}
        className={className}
      >
        {children}
      </a>
    );
  }

  return (
    <a
      download={download}
      href={href}
      onClick={onClickWithTracking}
      rel="external"
      target="_blank"
      {...restProps}
      ref={ref}
      className={className}
    >
      {children}
    </a>
  );
});
