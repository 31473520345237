import { Language } from '@yleisradio/areena-types';

type Key = keyof typeof translations;

const translations = {
  audioPlayer: {
    fi: 'Audiosoitin',
    sv: 'Ljudspelare',
  },
  chat: {
    fi: 'Chat',
    sv: 'Chatt',
  },
  expandPlayer: {
    fi: 'Laajenna soitin koko ikkunan kokoiseksi',
    sv: 'Visa spelaren i helskärm',
  },
  messageToStudio: {
    fi: 'Viesti studioon',
    sv: 'Meddelande till studion',
  },
  minimize: {
    fi: 'Pienennä',
    sv: 'Förminska',
  },
  minimizePlayer: {
    fi: 'Pienennä soitin',
    sv: 'Förminska spelaren',
  },
  programInfo: {
    fi: 'Ohjelman tiedot',
    sv: 'Tilläggsuppgifter',
  },
  play: {
    fi: 'Toista',
    sv: 'Spela upp',
  },
  pause: {
    fi: 'Keskeytä',
    sv: 'Paus',
  },
  replay10: {
    fi: 'Siirry taaksepäin 10 sekuntia',
    sv: 'Spola bakåt 10 sekunder',
  },
  forward10: {
    fi: 'Siirry eteenpäin 10 sekuntia',
    sv: 'Spola framåt 10 sekunder',
  },
  previous: {
    fi: 'Edellinen',
    sv: 'Tidigare',
  },
  next: {
    fi: 'Seuraava',
    sv: 'Nästa',
  },
  videoPlayer: {
    fi: 'Videosoitin',
    sv: 'Videospelare',
  },
  videoPlayerAndChat: {
    fi: 'Videosoitin ja chat',
    sv: 'Videospelare och chatt',
  },
  showMore: {
    fi: 'Näytä lisää',
    sv: 'Visa fler',
  },
  showMoreInformation: {
    fi: 'Näytä lisää',
    sv: 'Visa tilläggsuppgifter',
  },
  hideShowMoreInformation: {
    fi: 'Piilota',
    sv: 'Dölj tilläggsuppgifter',
  },
  shareTitle: {
    fi: 'Jaa ohjelma',
    sv: 'Dela programmet',
  },
  shareLabelCopyLink: {
    fi: 'Kopioi linkki',
    sv: 'Kopiera länk',
  },
  shareLabelCopyLinkCompleted: {
    fi: 'Kopioitu',
    sv: 'Kopierat',
  },
  shareLabelEmail: {
    fi: 'Sähköposti',
    sv: 'E-post',
  },
  deprecatedInstructions: {
    fi: 'Käytät selainta joka on vanhentunut tai ei kuulu Yle Areenan tukemiin selaimiin. Suosittelemme lataamaan Mozilla Firefoxin tai Google Chromen.',
    sv: 'Du använder en webbläsare som är föråldrad eller inte stöds av Yle Arenan. Vi rekommenderar att du laddar ner Mozilla Firefox eller Google Chrome.',
  },
  deprecatedLinkText: {
    fi: 'Lue lisää täältä',
    sv: 'Läs mera här',
  },
  deprecatedInstructionUrl: {
    fi: 'https://yle.fi/aihe/s/10005826',
    sv: 'https://svenska.yle.fi/s/10046445',
  },
  search: {
    fi: 'Hae',
    sv: 'Sök',
  },
  searchResults: {
    fi: 'Hakutulokset',
    sv: 'Sökresultat',
  },
  clearInput: {
    fi: 'Tyhjennä',
    sv: 'Radera',
  },
  clearSearchHistory: {
    fi: 'Tyhjennä hakuhistoria',
    sv: 'Töm sökhistorik',
  },
  searchContextTv: {
    fi: 'TV-ohjelmat',
    sv: 'TV-program',
  },
  searchContextRadio: {
    fi: 'Podcastit',
    sv: 'Poddar',
  },
  skipToContent: {
    fi: 'Hyppää sisältöön',
    sv: 'Gå till innehållet',
  },
  backToSeries: {
    fi: 'Siirry sarjaan',
    sv: 'Gå till serien',
  },
  related: {
    fi: 'Ohjelmaan liittyvää',
    sv: 'Relaterat till programmet',
  },
  notificationRemoveFailed: {
    fi: 'Poistaminen epäonnistui.',
    sv: 'Raderingen misslyckades.',
  },
  notificationHelpTextGeneric: {
    fi: 'Ole hyvä ja yritä uudelleen.',
    sv: 'Var god försök på nytt.',
  },
  menu: {
    fi: 'Valikko',
    sv: 'Meny',
  },
  timeline: {
    fi: 'aikajana',
    sv: 'tidslinje',
  },
  queue: {
    fi: 'Soittojono',
    sv: 'Kö',
  },
  nextInQueue: {
    fi: 'Seuraavaksi soittojonossa',
    sv: 'Nästa i kön',
  },
  nextInSeries: {
    fi: 'Seuraavaksi sarjan listalla',
    sv: 'Nästa på listan',
  },
  recommendationsInQueue: {
    fi: 'Automaattinen suosittelu',
    sv: 'Automatiserade rekommendationer',
  },
  recommendationsQueueInfoText: {
    fi: 'Sinulle soitetaan kuuntelemaasi ohjelmaan perustuvia suosituksia.',
    sv: 'Vi spelar upp rekommendationer utgående från vilket program du lyssnat på.',
  },
  hide: {
    fi: 'Pienennä',
    sv: 'Dölj',
  },
  nowPlaying: {
    fi: 'Nyt soi',
    sv: 'Nu spelas',
  },
  emptyQueue: {
    fi: 'Soittojonosi on vielä tyhjä. Voit lisätä lempiohjelmasi tälle listalle kuunneltavaksi.',
    sv: 'Din spellista är ännu tom. Du kan lägga till dina favoritprogram i listan för att lyssna på dem.',
  },
  loginToUseQueue: {
    fi: 'Käyttääksesi soittojonoa, ole hyvä ja kirjaudu sisään.',
    sv: 'För att du ska se din kö måste du logga in.',
  },
  hour: {
    fi: 'tunti',
    sv: 'en timme',
  },
  hours: {
    fi: 'tuntia',
    sv: 'timmar',
  },
  minute: {
    fi: 'minuutti',
    sv: 'en minut',
  },
  minutes: {
    fi: 'minuuttia',
    sv: 'minuter',
  },
  second: {
    fi: 'sekunti',
    sv: 'en sekund',
  },
  seconds: {
    fi: 'sekuntia',
    sv: 'sekunder',
  },
  showMoreGuide: {
    fi: 'Näytä lisää ohjelmia',
    sv: 'Visa fler',
  },
  showLessGuide: {
    fi: 'Näytä vähemmän ohjelmia',
    sv: 'Visa mindre',
  },
  selectRegionalChannel: {
    fi: 'Valitse alueradiokanava',
    sv: 'Välj regional radiokanal',
  },
  linkToProgramPage: {
    fi: 'Ohjelman sivulle',
    sv: 'Till programsidan',
  },
  previousDay: {
    fi: 'Edellinen päivä',
    sv: 'Föregående dag',
  },
  nextDay: {
    fi: 'Seuraava päivä',
    sv: 'Följande dag',
  },
  changeDate: {
    fi: 'Vaihda näytettävää päivää',
    sv: 'Byt vilken dag som visas',
  },
  showPastPrograms: {
    fi: 'Näytä menneet ohjelmat',
    sv: 'Visa redan sända program',
  },
  listenToBroadcast: {
    fi: 'Kuuntele lähetystä',
    sv: 'Lyssna på sändningen',
  },
  addToQueue: {
    fi: 'Lisää soittojonoon',
    sv: 'Lägg till i uppspelningskön',
  },
  removeFromQueue: {
    fi: 'Soittojonossa',
    sv: 'I uppspelningskön',
  },
  programs: {
    fi: 'Ohjelmat',
    sv: 'Program',
  },
  today: {
    fi: 'tänään',
    sv: 'idag',
  },
  endOfSchedule: {
    fi: 'Lähetykset päättyneet tältä päivältä',
    sv: 'Dagens sändningar har avslutats',
  },
  noTransmissions: {
    fi: 'Ei lähetyksiä',
    sv: 'Inga sändningar',
  },
  noScheduleAvailableYet: {
    fi: 'Lähetystietoja ei ole vielä saatavilla',
    sv: 'Information om sändningar inte ännu tillgänglig',
  },
  and: {
    fi: 'ja',
    sv: 'och',
  },
  programAudioNoRights: {
    fi: 'Kuunneltavissa vain suorana',
    sv: 'Enbart direkt',
  },
  programVideoNoRights: {
    fi: 'Katsottavissa vain suorana',
    sv: 'Enbart direkt',
  },
  programOndemandAudioUpcoming: {
    fi: 'Tulossa kuunneltavaksi',
    sv: 'På kommande',
  },
  programOndemandVideoUpcoming: {
    fi: 'Tulossa katsottavaksi',
    sv: 'På kommande',
  },
  channelErrorMessage: {
    fi: 'Kanavan ohjelmatietoja ei saatavilla',
    sv: 'Programuppgifterna för kanalen är inte tillgängliga',
  },
  availableInAreena: {
    fi: 'Saatavilla Areenassa',
    sv: 'Tillgängligt på Arenan',
  },
  upcomingInAreena: {
    fi: 'Tulossa Areenaan',
    sv: 'På kommande till Arenan',
  },
  mute: {
    fi: 'Mykistä',
    sv: 'Ljud av',
  },
  volume: {
    fi: 'äänenvoimakkuuden säädin',
    sv: 'volymkontroll',
  },
  playbackRate: {
    fi: 'Toistonopeus',
    sv: 'Hastighet',
  },
  playbackRateNormal: {
    fi: 'Normaali',
    sv: 'Normal',
  },
  songs: {
    fi: 'Kappaleet',
    sv: 'Låtarna',
  },
  close: {
    fi: 'Sulje',
    sv: 'Stäng',
  },
  closePlayer: {
    fi: 'Sulje soitin',
    sv: 'Stäng spelaren',
  },
  metaDescription: {
    fi: 'Yle Areena - Enemmän kuin ehdit katsoa ja kuunnella. Yle Areenassa on tarjolla radio- ja televisio-ohjelmia, suoria lähetyksiä sekä ohjelmatiedot.',
    sv: 'Yle Arenan - Det mesta av det bästa. Yle Arenan erbjuder radio- och tv-program, direktsändningar och programuppgifter.',
  },
  comingNext: {
    fi: 'Seuraavaksi',
    sv: 'Nästa',
  },
  radioGuide: {
    fi: 'Radio-opas',
    sv: 'Radioguide',
  },
  listLoadingFailed: {
    fi: 'Sisältölistan lataaminen epäonnistui',
    sv: 'Innehållslistan kunde inte laddas',
  },
  genericError: {
    fi: 'Tapahtui virhe.',
    sv: 'Ett fel uppstod.',
  },
  playbackStartError: {
    fi: 'Sisällön toistaminen epäonnistui',
    sv: 'Uppspelning av innehållet misslyckades',
  },
  queueToggleError: {
    fi: 'Soittojonon muokkaaminen epäonnistui',
    sv: 'Det gick inte att redigera uppspelningskön',
  },
  addedToQueue: {
    fi: 'Lisätty soittojonoon.',
    sv: 'Tillagd i uppspelningskön.',
  },
  removedFromQueue: {
    fi: 'Poistettu soittojonosta',
    sv: 'Raderad från uppspelningskön',
  },
  ownPage: {
    fi: 'Omat',
    sv: 'Mina',
  },
  personalInfo: {
    fi: 'Omat tiedot',
    sv: 'Mitt konto',
  },
  messageSettings: {
    fi: 'Viestiasetukset',
    sv: 'Nyhetsbrev',
  },
  mediaConsumption: {
    fi: 'Oma median kulutus',
    sv: 'Medieanvändningstid',
  },
  logIn: { fi: 'Kirjaudu', sv: 'Logga in' },
  logOut: { fi: 'Kirjaudu ulos', sv: 'Logga ut' },
  areena: {
    fi: 'Yle Areena',
    sv: 'Yle Arenan',
  },
  lastenAreena: {
    fi: 'Lasten Areena',
    sv: 'Barnens Arena',
  },
  mainMenu: {
    fi: 'Päävalikko',
    sv: 'Huvudmeny',
  },
  profileMenu: {
    fi: 'Yle Tunnus -valikko',
    sv: 'Yle Konto meny',
  },
  usersInitials: {
    fi: 'Käyttäjän nimikirjaimet',
    sv: 'Användarens initialer',
  },
  tv: {
    fi: 'TV',
    sv: 'TV',
  },
  showAllSearchResults: {
    fi: 'Kaikki tulokset',
    sv: 'Alla resultat',
  },
  searchResult: {
    fi: 'hakutulos',
    sv: 'träff',
  },
  searchResultsPlural: {
    fi: 'hakutulosta',
    sv: 'träffar',
  },
  noSearchResultsFoundTV: {
    fi: 'TV-ohjelmia ei löytynyt',
    sv: 'Inga TV-program hittades',
  },
  noSearchResultsFoundPodcasts: {
    fi: 'Podcasteja ei löytynyt',
    sv: 'Inga poddar hittades',
  },
  tvProgramsFound: {
    fi: 'TV-ohjelmista löydettiin',
    sv: 'Bland TV-program hittades',
  },
  podcastsFound: {
    fi: 'Podcasteista löydettiin',
    sv: 'Bland Poddar hittades',
  },
  nothingFoundPodcasts: {
    fi: 'Podcasteja eikä TV-ohjelmia löytynyt.',
    sv: 'Inga poddar eller TV-program hittades.',
  },
  nothingFoundTV: {
    fi: 'TV-ohjelmia eikä podcasteja löytynyt.',
    sv: 'Inga TV-program eller poddar hittades.',
  },
  noSearchResultsFound: {
    fi: 'Hakutuloksia ei löytynyt',
    sv: 'Inga träffar hittades',
  },
  categories: {
    fi: 'Kategoriat',
    sv: 'Kategorier',
  },
  notFoundHeading: {
    fi: 'Etsimääsi sisältöä ei löytynyt',
    sv: 'Sidan du letar efter hittades inte',
  },
  notFoundText: {
    fi: 'Löydät Yle Areenan sarjat, podcastit ja suorat etusivulta.',
    sv: 'Du hittar Yle Arenans serier, poddar och direktsändningar på första sidan.',
  },
  notFoundButton: {
    fi: 'Siirry etusivulle',
    sv: 'Till första sidan',
  },
  podcasts: { fi: 'Podcastit', sv: 'Poddar' },
  broadcasts: { fi: 'Suorat', sv: 'Direkt' },
  searchAndBrowse: { fi: 'Hae ja selaa', sv: 'Sök och bläddra' },
  otherLanguageLink: { fi: 'Yle Arenan på svenska', sv: 'Yle Areena suomeksi' },
  footerSupportTitle: { fi: 'Ohjeet', sv: 'Instruktioner' },
  footerSomeTitle: { fi: 'Areena somessa', sv: 'Arenan på sociala medier' },
  footerFeedbackTitle: { fi: 'Anna palautetta', sv: 'Respons' },
  tvApps: { fi: 'TV-sovellukset', sv: 'TV-appar' },
  androidApps: {
    fi: 'Android-mobiilisovellukset',
    sv: 'Android-mobilappar',
  },
  iPhoneAndiPad: { fi: 'iPhone ja iPad', sv: 'iPhone och iPad' },
  allInstructions: { fi: 'Kaikki ohjeet', sv: 'Alla instruktioner' },
  customerService: { fi: 'Asiakaspalvelu', sv: 'Kundservice' },
  yleCorporation: { fi: 'Yle yhtiönä', sv: 'Om Yle' },
  yleContactInfo: {
    fi: 'Ylen perus- ja yhteystiedot',
    sv: 'Yles grund- och kontaktuppgifter',
  },
  cookiePolicy: { fi: 'Evästekäytännöt', sv: 'Cookiepolicy' },
  cookieSettings: { fi: 'Evästeasetukset', sv: 'Cookieinställningar' },
  privacyPolicy: { fi: 'Tietosuojalauseke', sv: 'Integritetsdeklaration' },
  facebook: { fi: 'Facebook', sv: 'Areena - Facebook' }, //SIC!
  instagram: { fi: 'Instagram', sv: 'Areena - Instagram' }, //SIC!
  tikTok: { fi: 'TikTok', sv: '' },
  youtube: { fi: 'Youtube', sv: '' },
  arenanFacebook: { fi: '', sv: 'Svenska Yle & Arenan - Facebook' },
  arenanInstagram: { fi: '', sv: 'Svenska Yle & Arenan - Instagram' },
  yleUrl: { fi: 'Yle.fi', sv: 'svenska.yle.fi' },
  songFetchError: {
    fi: 'Nyt soivan kappaleen tietoja ei saatu ladattua.',
    sv: 'Det gick inte att ladda informationen om låten som spelas just nu.',
  },
  externalChannelsMissingNoticeTitle: {
    fi: 'Puutteita ohjelmatiedoissa.',
    sv: 'Brister i programinfon.',
  },
  externalChannelsMissingNoticeDescription: {
    fi: 'Muiden kuin Ylen tv-kanavien ohjelmatiedot ovat toistaiseksi poissa käytöstä.',
    sv: 'För tillfället går det endast att se programinfon för Yles tv-kanaler.',
  },
} satisfies Record<string, Record<Language, string>>;

export function getTranslation(key: Key, language: Language): string {
  return translations[key][language];
}
