import { forwardRef, PropsWithChildren } from 'react';
import styles from './SearchBar.module.scss';
import SearchIcon from 'assets/search.svg';
import CloseIcon from 'assets/close.svg';
import { VisuallyHidden } from '@radix-ui/react-visually-hidden';
import { useTranslation } from 'hooks/useTranslation';

type Props = {
  placeholder: string;
  searchQuery: string | null;
  setQuery(newQuery: string | null): void;
  inputId: string;
};

export const SearchBar = forwardRef<HTMLInputElement, PropsWithChildren<Props>>(
  function SearchBar({ placeholder, searchQuery, setQuery, inputId }, ref) {
    const t = useTranslation();

    return (
      <div className={styles.inputContainer}>
        <SearchIcon className={styles.inputIcon} aria-hidden />
        <VisuallyHidden asChild>
          <label htmlFor={inputId}>{t('search')}</label>
        </VisuallyHidden>
        <input
          value={searchQuery || ''}
          type="search"
          id={inputId}
          className={styles.searchBar}
          placeholder={placeholder}
          autoComplete="off"
          onInput={(e) => {
            setQuery(e.currentTarget.value);
          }}
          ref={ref}
        />
        {searchQuery !== null && searchQuery.length > 0 ? (
          <button
            className={styles.clearButton}
            onClick={() => {
              setQuery('');
            }}
            type="button"
          >
            <CloseIcon aria-hidden className={styles.clearIcon} />
            <VisuallyHidden>{t('clearInput')}</VisuallyHidden>
          </button>
        ) : null}
      </div>
    );
  }
);
