import React, { forwardRef, PropsWithChildren } from 'react';

type Props = {
  className?: string | undefined;
  id?: string;
};

export const UnorderedListMicrodata = forwardRef<
  HTMLUListElement,
  PropsWithChildren<Props>
>(function UnorderedListMicrodata({ className, id, children }, ref) {
  return (
    <ul
      className={className}
      id={id}
      itemScope
      itemType="https://schema.org/ItemList"
      ref={ref}
    >
      {children}
    </ul>
  );
});
