import * as ToggleGroup from '@radix-ui/react-toggle-group';
import { Service } from '@yleisradio/areena-types';
import { useIsomorphicLayoutEffect } from 'hooks/useIsomorphicLayoutEffect';
import { useTranslation } from 'hooks/useTranslation';
import React, { useRef, useTransition } from 'react';
import styles from './ServiceSwitch.module.scss';

type Props = {
  activeService: Service;
  setActiveService: (service: Service) => void;
  onClick?: () => void;
};

export const ServiceSwitch: React.FC<Props> = ({
  activeService,
  setActiveService,
  onClick,
}) => {
  const activeMarkerRef = useRef<HTMLDivElement>(null);
  const tvButtonRef = useRef<HTMLButtonElement>(null);
  const radioButtonRef = useRef<HTMLButtonElement>(null);
  const [, startTransition] = useTransition();
  const t = useTranslation();

  const adjustMarkerPosition = () => {
    const markerElement = activeMarkerRef.current;
    const tvButton = tvButtonRef.current;
    const radioButton = radioButtonRef.current;

    if (!markerElement || !tvButton || !radioButton) return;

    const tvButtonWidth = tvButton.getBoundingClientRect().width;
    const radioButtonWidth = radioButton.getBoundingClientRect().width;

    markerElement.style.width = `${
      activeService === 'radio' ? radioButtonWidth : tvButtonWidth
    }px`;
    markerElement.style.transform =
      activeService === 'radio'
        ? `translateX(${radioButton.offsetLeft}px)`
        : '';
  };

  const onChange = (value: Service) => {
    if (onClick) {
      onClick();
    }

    startTransition(() => {
      if (value) setActiveService(value);
    });

    adjustMarkerPosition();
  };

  useIsomorphicLayoutEffect(() => {
    adjustMarkerPosition();

    const onResize = () => adjustMarkerPosition();

    window.addEventListener('resize', onResize);

    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, [activeService]);

  return (
    <ToggleGroup.Root
      className={styles.root}
      onValueChange={onChange}
      orientation="horizontal"
      type="single"
      value={activeService}
    >
      <div className={styles.activeMarker} ref={activeMarkerRef} />

      <ToggleGroup.Item value="tv" className={styles.item} ref={tvButtonRef}>
        {t('searchContextTv')}
      </ToggleGroup.Item>
      <ToggleGroup.Item
        value="radio"
        className={styles.item}
        ref={radioButtonRef}
      >
        {t('searchContextRadio')}
      </ToggleGroup.Item>
    </ToggleGroup.Root>
  );
};
