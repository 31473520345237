import {
  Control,
  ControlResponse,
  Language,
  Notification,
  Operation,
} from '@yleisradio/areena-types';
import { useState } from 'react';
import { executeOperation } from 'services/areena-api/fetchers';
import { OperationState } from 'components/Controls/Control/type';
import { ErrorWithNotifications } from 'utils/errorWithNotifications';
import { toast } from 'components/Notifications';
import { getTranslation } from 'services/translations';
import { useUILanguage } from 'hooks/useUILanguage';
import { Action } from './type';
import { isOperator } from 'components/Controls/Control/typeGuards';
import { KeyedMutator } from 'swr';

type ControlErrorMessage = {
  notification: string;
  helpText: string;
};

const getOperationErrorNotification = (
  operation: Operation,
  language: Language
): ControlErrorMessage | undefined => {
  if (operation.method === 'DELETE') {
    return {
      notification: getTranslation('notificationRemoveFailed', language),
      helpText: getTranslation('notificationHelpTextGeneric', language),
    };
  }
};

function getUIMessage(notifications?: Notification[]): string | undefined {
  if (notifications) {
    const uiMessages = notifications.filter((n) => !!n.uiMessage);
    if (uiMessages.length > 0) {
      return uiMessages.reduce(
        (message, { uiMessage }) =>
          `${message}${message ? ', ' : ''}${uiMessage}`,
        ''
      );
    }
  }
}

function handleOperationNotification(
  operation: Operation,
  language: Language,
  error: Error
) {
  const controlErrorMessage = getOperationErrorNotification(
    operation,
    language
  );

  const messageFromApi =
    error instanceof ErrorWithNotifications
      ? getUIMessage(error.notifications)
      : null;

  if (controlErrorMessage) {
    const { notification, helpText: helpTextGeneric } = controlErrorMessage;
    const helpText = messageFromApi || helpTextGeneric;

    toast(notification, 'error', helpText);
  } else if (messageFromApi && messageFromApi.length > 0) {
    toast(messageFromApi, 'error');
  }
}

type Props = {
  control: Control | null;
  setOperationState: ((newState: OperationState) => void) | undefined;
  mutateControl: KeyedMutator<ControlResponse | null>;
};

export function useOperatorAction({
  control,
  setOperationState,
  mutateControl,
}: Props): Action {
  const [isExecuting, setIsExecuting] = useState(false);
  const language = useUILanguage();

  async function operationAction() {
    if (!isOperator(control)) {
      return;
    }

    if (isExecuting) {
      return;
    }

    const { operation } = control;
    setIsExecuting(true);
    executeOperation(operation)
      .then((response) => {
        setOperationState?.('complete');
        mutateControl(response || undefined, {
          revalidate: false,
        });
      })
      .catch((e) => {
        setOperationState?.('failed');
        mutateControl();
        handleOperationNotification(operation, language, e);
      })
      .finally(() => {
        setIsExecuting(false);
      });
  }

  return {
    runAction: operationAction,
    executing: false,
  };
}
