import { Control } from '@yleisradio/areena-types';
import { BackLink } from 'components/AlternateButton/BackLink';
import {
  AddToQueueButton,
  Button,
  ButtonPadding,
  ButtonSize,
  ButtonVariant,
  PlayButton,
} from 'components/Button';
import { SeriesLink } from 'components/Button/SeriesLink';
import {
  ControlLink,
  DSLink,
  LinkIconPosition,
  LinkTextSize,
  LinkTheme,
} from 'components/DSLink';
import { isNavigator } from './typeGuards';
import { useIcon } from './useIcon';

export type ControlPresentationType = 'button' | 'link';

type ControlPresentationProps = {
  'aria-controls'?: string | undefined;
  'aria-expanded'?: boolean | undefined;
  as: ControlPresentationType;
  buttonPadding?: ButtonPadding | undefined;
  buttonSize?: ButtonSize | undefined;
  buttonVariant?: ButtonVariant | undefined;
  width?: 'full' | undefined;
  control: Control;
  executingAction?: boolean;
  onClick?: () => void;
  textSize?: LinkTextSize | undefined;
  iconPosition?: LinkIconPosition | undefined;
  theme?: LinkTheme | undefined;
};

export const ControlPresentation: React.FunctionComponent<
  ControlPresentationProps
> = (props) => {
  const { control, onClick, textSize, iconPosition, theme } = props;
  const Icon = useIcon(control);

  if (control.tag === 'cookie-consent') {
    return (
      <DSLink
        text={control.title}
        linkVariant="internal"
        textSize="s"
        pointer={null}
        onClick={onClick}
        theme="light"
      />
    );
  }

  if (props.as === 'link') {
    return (
      <ControlLink
        control={control}
        onClick={onClick}
        textSize={textSize}
        iconPosition={iconPosition}
        theme={theme}
      />
    );
  }

  const {
    buttonPadding: padding,
    buttonSize: size,
    buttonVariant: variant,
    width: width,
  } = props;

  if (control.tag === 'play') {
    return (
      <PlayButton
        control={control}
        onClick={onClick}
        padding={padding}
        size={size}
        variant={variant}
        width={width}
      />
    );
  }

  if (control.tag === 'series') {
    return <SeriesLink control={control} onClick={onClick} />;
  }

  if (control.tag === 'back') {
    return <BackLink control={control} onClick={onClick} />;
  }

  if (control.tag === 'queue') {
    return (
      <AddToQueueButton
        control={control}
        onClick={onClick}
        padding={padding}
        size={size}
        variant={variant}
      />
    );
  }

  return (
    <Button
      text={control.title || ''}
      disabled={!!control.disabled}
      download={control.tag === 'downloadLink'}
      iconLeft={Icon}
      onClick={onClick}
      padding={padding}
      pointer={isNavigator(control) ? control.destination : undefined}
      size={size}
      variant={variant}
      aria-expanded={props['aria-expanded']}
      aria-controls={props['aria-controls']}
    />
  );
};
