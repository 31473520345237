import classNames from 'classnames';
import React from 'react';
import InfoIcon from 'assets/infoIcon.svg';
import AlertTriangleIcon from 'assets/alertTriangle.svg';
import CloseIcon from 'assets/close.svg';
import { Controls } from 'components/Controls';
import styles from './Notification.module.scss';
import { Notification as NotificationType } from '@yleisradio/areena-types';

export type NotificationStyle =
  | 'transparent'
  | 'primary'
  | 'text'
  | 'error'
  | undefined;

interface Props {
  notification: NotificationType;
  notificationStyle: NotificationStyle;
  onClose?: () => void;
  helpText?: string | undefined;
  isToast?: boolean;
}

type IconProps = {
  notificationStyle: NotificationStyle;
  className: string | undefined;
  'aria-hidden': boolean;
};

const Icon = ({ notificationStyle, ...props }: IconProps) => {
  switch (notificationStyle) {
    case 'text':
      return null;
    case 'error':
      return <AlertTriangleIcon {...props} />;
    default:
      return <InfoIcon {...props} />;
  }
};

type NotificationTextProps = {
  notification: string | undefined;
  helpText: string | undefined;
};

const NotificationText = ({
  notification,
  helpText,
}: NotificationTextProps) => {
  if (helpText) {
    return (
      <span className={styles.text}>
        {notification}
        <span className={styles.helpText}>{helpText}</span>
      </span>
    );
  }

  return <>{notification}</>;
};

export const Notification: React.FunctionComponent<Props> = ({
  notification,
  notificationStyle = 'transparent',
  onClose,
  helpText,
  isToast = false,
}) => {
  const className = classNames(
    styles.notification,
    styles[notificationStyle],
    isToast && styles.toast
  );
  const role = isToast ? 'alert' : undefined;

  return (
    <span className={className} role={role}>
      <Icon
        aria-hidden
        className={styles.icon}
        notificationStyle={notificationStyle}
      />
      <NotificationText
        notification={notification?.uiMessage}
        helpText={helpText}
      />
      {onClose && (
        <button onClick={onClose} className={styles.removeButton}>
          <CloseIcon className={styles.icon} />
        </button>
      )}
      {notification.controls && notification.controls.length > 0 && (
        <div className={styles.controlWrapper}>
          <Controls
            controls={notification.controls}
            as="button"
            size={'sm'}
            variation={'primary'}
            direction={'horizontal'}
          />
        </div>
      )}
    </span>
  );
};
